import './styles.scss';
import 'jquery-lazy'

import './rocket.scss';
import './fonts.scss';
import './footer/footer.scss';

jQuery(function () {
    jQuery('.lazy').Lazy();
});


document.addEventListener("DOMContentLoaded", function () {
    // Funktion zum Erstellen eines Tooltip
    function createTooltip(element) {
        const tooltipText = element.getAttribute('data-custom-title');
        const tooltip = document.createElement('div');
        tooltip.className = 'custom-tooltip';
        tooltip.textContent = tooltipText;
        document.body.appendChild(tooltip);


        // Berechnung der Tooltip-Position
        const elementRect = element.getBoundingClientRect();
        const scrollX = window.scrollX;
        const scrollY = window.scrollY;
        const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

        // Positioniere das Tooltip über dem Element, unter Berücksichtigung des Scrollens und der Viewport-Grenzen
        let tooltipX = elementRect.left + scrollX + (elementRect.width / 2);
        const tooltipY = elementRect.top + scrollY - tooltip.offsetHeight - 5; // 5px über dem Element
        const tooltipWidth = tooltip.offsetWidth;

        // Anpassung der X-Position, falls das Tooltip rechts über den Bildschirmrand hinausragt
        if (tooltipX + tooltipWidth / 2 > viewportWidth) {
            tooltipX = viewportWidth - (tooltipWidth / 2);
        }

        // Anpassung der X-Position, falls das Tooltip links über den Bildschirmrand hinausragt
        if (tooltipX - tooltipWidth / 2 < 0) {
            tooltipX = tooltipWidth / 2;
        }

        tooltip.style.left = `${tooltipX - tooltipWidth / 2}px`;
        tooltip.style.top = `${tooltipY}px`;

        return tooltip;
    }

    // Funktion zum Entfernen eines Tooltip
    function removeTooltip(tooltip) {
        document.body.removeChild(tooltip);
    }

    let currentTooltip = null;

    // Event Listeners für Elemente mit dem data-toggle="tooltip" Attribut
    function addTooltipListeners(scope = "") {
        const tooltipElements = $('[data-custom-toggle="tooltip"]', scope);

        tooltipElements.off('mouseenter');
        tooltipElements.on('mouseenter', (event) => {
            currentTooltip = createTooltip(event.currentTarget)
        });

        tooltipElements.off('focus');
        tooltipElements.on('focus', (event) => {
            currentTooltip = createTooltip(event.currentTarget)
        });

        tooltipElements.off('mouseleave');
        tooltipElements.on('mouseleave', () => {
            removeTooltip(currentTooltip)
        });

        tooltipElements.off('blur');
        tooltipElements.on('blur', () => {
            removeTooltip(currentTooltip)
        });

        tooltipElements.off('touchstart');
        tooltipElements.on('touchstart', (e) => {
            e.preventDefault(); // Verhindere die Standardaktion bei Berührung
            if (currentTooltip) {
                removeTooltip(currentTooltip);
                currentTooltip = null;
            } else {
                currentTooltip = createTooltip(e.currentTarget);
            }
        });
    }

    addTooltipListeners();

    $('#lang-options button').on('click', function (e) {
        e.preventDefault();
        const lang = $(this).data('lang');
        const currentLang = $(this).data('current-lang');
        const defaultLang = $(this).data('default-lang');

        if (lang === currentLang) {
            return;
        }

        window.locale = lang;
        let url = '';

        const currentPath = window.location.pathname;
        if (lang === defaultLang) {
            url = currentPath.replace(`/${currentLang}/`, '/');
        } else {
            if (currentLang === defaultLang) {
                url = currentPath.replace('/', `/${lang}/`);
            } else {
                url = currentPath.replace(`/${currentLang}/`, `/${lang}/`);
            }
        }

        window.location.href = url + '?_locale=' + lang;
    });
});

/**
 * Add getTranslation method to disco2app object
 * @param {string} key - The translation key to look up (supports dot notation for nested keys)
 * @param {string} [defaultValue=''] - Fallback value if translation is not found
 * @returns {string} - Translated text or default value
 */
disco2app.getTranslation = function(key, defaultValue = '') {
    try {
        const keyParts = key.split('.');
        
        let currentTranslations = this.translations;
        
        for (const part of keyParts) {
            if (currentTranslations && typeof currentTranslations === 'object') {
                currentTranslations = currentTranslations[part];
            } else {
                return defaultValue;
            }
        }
        
        return currentTranslations || defaultValue;
    } catch (error) {
        console.warn(`Translation error for key "${key}":`, error);
        return defaultValue;
    }
};